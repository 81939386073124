@import 'src/style/globals/_functions';
.footer {
  background-color: var(--dark-blue);
  color: var(--white);

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: rem(60) 0;
    gap: rem(60);

    .logo {
      width: clamp(7.875rem, 6.875rem + 5vw, 10.75rem);
      height: clamp(2.375rem, 2.0707rem + 1.5217vw, 3.25rem);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(24);

      .title {
        color: #ffffff9c;
        font-weight: 900;
        text-transform: uppercase;
      }

      button,
      a {
        text-transform: capitalize;
        color: var(--white);
        transition: color 250ms ease;
        // font-weight: 400;

        &:hover {
          color: #d9d9d9;
        }
      }
    }

    @media (max-width: 500px) {
      .top {
        width: 100%;
      }
    }
  }

  .rights {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(61);
    padding-bottom: rem(44);

    p {
      text-align: center;
    }
  }

  .socials {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row !important;

    &.second {
      display: none;
    }

    svg {
      width: var(34);
      height: var(34);
      fill: white;
      transition: fill 250ms ease;
    }

    a:hover {
      svg {
        fill: #d9d9d9;
      }
    }

    @media (max-width: 700px) {
      &.second {
        display: flex;
      }

      &.first {
        display: none;
      }
    }
  }

  .email {
    text-transform: none !important;
  }
}
