@import 'src/style/globals/_functions';
.fakeHeader {
  height: rem(80);
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  background-color: var(--white);
  z-index: 999999;

  .mobileNav {
    display: none;
  }

  @media (max-width: 700px) {
    .mobileNav {
      display: block;
    }

    .close.mobileNav {
      display: none;
    }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(80);
  font-weight: 700;

  .logo {
    height: rem(31);
    width: rem(100);

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(45);

    a {
      transition: color 250ms ease;

      &:hover {
        color: #103176;
      }
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  .support {
    display: flex;
    align-items: center;
    justify-content: center;

    .HamburgerSvg {
      display: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      transition: color 250ms ease;

      svg {
        stroke: var(--fc-base);
        transition: stroke 250ms ease;
      }

      &:hover {
        color: #103176;

        svg {
          stroke: #103176;
        }
      }
    }

    @media (max-width: 700px) {
      a {
        display: none;
      }

      .HamburgerSvg {
        display: block;
      }
    }
  }
}

.mobileNav {
  position: fixed;
  top: rem(80);
  background-color: var(--white);
  z-index: 999999;
  height: 100%;
  left: 0;
  width: 100%;
  font-weight: 700;

  .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(28);
    margin-top: rem(20);
    margin-bottom: rem(28);

    a {
      transition: color 250ms ease;

      &:hover {
        color: #103176;
      }
    }
  }

  .cta {
    margin-top: rem(45);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: rem(18);

    svg {
      fill: var(--white);
      height: clamp(1.125rem, 0.9293rem + 0.9783vw, 1.6875rem);
      width: clamp(1.125rem, 0.9293rem + 0.9783vw, 1.6875rem);
    }

    a {
      background-color: var(--dark-blue);
      border-radius: 8px;
      border: 1px solid #111827;
      padding: clamp(0.375rem, 0.3098rem + 0.3261vw, 0.5625rem)
        clamp(1.125rem, 1.0598rem + 0.3261vw, 1.3125rem);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: rem(7);

      p {
        color: var(--white);
        text-align: left;
        font-weight: 500;
        font-size: clamp(0.4375rem, 0.394rem + 0.2174vw, 0.5625rem);

        .available {
          font-size: var(--fs-base);
        }
      }
    }
  }

  .mobileSupport {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    transition: color 250ms ease;

    svg {
      stroke: var(--fc-base);
      transition: stroke 250ms ease;
    }

    &:hover {
      color: #103176;

      svg {
        stroke: #103176;
      }
    }
  }
}
