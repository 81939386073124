@use "sass:color";

// Each color filled here will create a css variable exposed globally
$colors: (
  // Colors
  "white": rgb(255, 255, 255),
  "cream": rgb(251, 247, 238),
  "grey": rgb(144, 144, 144),
  "yellow": rgb(244, 196, 48),
  "black": rgb(0, 0, 0),
  "red": rgb(255, 0, 0),
  "blue": rgb(65, 96, 255),
  "dark-blue": rgb(17, 24, 39),
  "green": rgb(0, 255, 106)
);

:root {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
    // for safari use case: https://ambientimpact.com/web/snippets/safari-bug-with-gradients-that-fade-to-transparent
    --#{$name}-transparent: #{color.change($color, $alpha: 0)};
  }
}

body {
  background: var(--white);
  color: var(--dark-blue);
}
