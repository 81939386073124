@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-Light.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-Light.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-LightItalic.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-LightItalic.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-Regular.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-Regular.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-Italic.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-Italic.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-Medium.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-Medium.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-MediumItalic.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-MediumItalic.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-Bold.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-Bold.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-BoldItalic.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-Black.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-Black.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src:
    url("/assets/fonts/Satoshi/Satoshi-BlackItalic.woff2") format("woff2"),
    url("/assets/fonts/Satoshi/Satoshi-BlackItalic.woff") format("woff"),
    url("/assets/fonts/Satoshi/Satoshi-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

:root {
  // fonts sizes
  --fs-base: clamp(0.75rem, 0.663rem + 0.4348vw, 1rem); // 12px → 16px
  --fs-header: clamp(1.5rem, 0.7174rem + 3.913vw, 3.75rem); // 24px → 60px
  --fs-secondary-header: clamp(
    1rem,
    0.3696rem + 3.1522vw,
    2.8125rem
  ); // 16px → 45px

  // fonts family
  --ff-secondary: "Satoshi", sans-serif;
  --ff-base: "Satoshi", sans-serif;

  // fonts color
  --fc-base: #111827;
}

html {
  font-size: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  line-height: 1.6;
  font-family: var(--ff-base);
  font-size: var(--fs-base);
  color: var(--fc-base);
  font-weight: 400;
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  font-weight: 600;
  font-family: var(--ff-base);
}
