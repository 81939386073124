/* WebKit manipulate Scrollbar */

* {
  scrollbar-width: none !important;
  scrollbar-color: var(--secondary) transparent;
}

*::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

*::-webkit-scrollbar-thumb {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  background: var(--secondary) !important;
}

*::-webkit-scrollbar-thumb:active {
  background: rgb(1, 126, 126);
}

*::-webkit-scrollbar-track {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}

html,
body {
  overscroll-behavior: none;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}
