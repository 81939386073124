#mainContainer {
  /* 12.00px → 48.00px left and right */
  --wrapper-margin: clamp(1.5rem, calc(-1.3rem + 14.01vw), 6rem);

  width: min(100% - var(--wrapper-margin), 1327px);
  margin-inline: auto;
  position: relative;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  font-weight: 600;
  background: transparent;
  color: var(--white);
  border: solid rem(1) var(--white);
  border-radius: rem(16);
  padding: rem(4) rem(16);
  transition:
    border-color 0.2s ease,
    color 0.2s ease;
  mix-blend-mode: difference;

  &:hover {
    border-color: transparent;
    color: var(--secondary);
  }
}

.dragger {
  overflow: hidden;
}

.dragger.is-disabled {
  opacity: 0.5;
  cursor: default;
}

.dragger.is-disabled * {
  cursor: default;
}

.sr-only {
  position: absolute;
  width: rem(1);
  height: rem(1);
  padding: 0;
  margin: rem(-1);
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.hide_for_mobile {
  display: none;
}

.hide_for_desktop {
  display: none;
}

.flow > * + * {
  margin-top: 1em;
}
